<h2>User Administration</h2>
<div class="horizontal" fxLayout="row" style="padding: 5px;">
    <div class="center-vertical" fxFlex="50%">Total: {{this.showedRows.length}}</div>
    <div class="center-vertical" fxFlex="50%">
        <input class="float-right col-input" style="width: 180px; margin-left: auto" (keyup)="applyFilterLocal($event.target, 'all_column')" placeholder="Search">
    </div>
</div>
<div class="table_div">
    <table>
        <thead>
            <tr>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id', 'number')"></mat-icon>
                    <div class="column-name">Id</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'first_name', 'string')"></mat-icon>
                    <div class="column-name">First Name</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'first_name')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'last_name', 'string')"></mat-icon>
                    <div class="column-name">Last Name</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'last_name')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'email', 'string')"></mat-icon>
                    <div class="column-name">Email</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'email')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'phone', 'string')"></mat-icon>
                    <div class="column-name">Phone</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'phone')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'last_active_date', 'date')"></mat-icon>
                    <div class="column-name">Active Date</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'last_active_date')"></div>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody #row_tbody *ngFor="let row of showedRows; let i = index">
            <tr (click)="toggleRow(row, $event.target, row_tbody)">
                <td>{{ row['id'] }}</td>
                <td>{{ row['first_name'] }}</td>
                <td>{{ row['last_name'] }}</td>
                <td>{{ row['email'] }}</td>
                <td>{{ row['phone'] }}</td>
                <td>{{ row['last_active_date'] }}</td>
            </tr>
            <tr class="expand_detail" *ngIf="row['expanded']">
                <td colspan="6">
                    <div fxLayout="row" fxLayout.xs="column">
                        <div fxLayout="row">
                            <div style="display: flex; align-items: center; padding-right: 5px;">Privilege</div>
                            <div fxFlex="80%">
                                <mat-select placeholder="Level"  [value]="row['privilege']" (selectionChange)="updatePrivilege(row, $event.value)">
                                    <mat-option [value]="1" >Level 1</mat-option>
                                    <mat-option [value]="2" >Level 2</mat-option>
                                    <mat-option [value]="3" >Level 3</mat-option>
                                    <mat-option [value]="4" >Level 4</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div style="position: relative; display: flex; align-items: center; margin-left: 15px;">
                            <span class="switch-toggle" [ngClass]="{'switch-checked': row['authorized']}" (click)="toggleUserAuthorized(row)">
                              <small></small>
                            </span>
                            <label>Authorized</label>
                        </div>
                        
                    </div>
                    <div fxLayout="row" fxLayout.xs="column">
                        <div fxFlex="33.33%" style="padding-top: 20px;">
                            <div>
                                <h3>Enovsky</h3>
                                <div style="position: relative; display: flex; margin-left: auto; align-items: center; padding-top: 10px;" *ngFor="let page of enovskyPages">
                                    <input class="form-check-input" [checked]="row['authorization']['enovsky'].includes(page['id_page'])" style="font-size:large; cursor: pointer; " type="checkbox" role="switch" (change)="checkPage('enovsky', row, page, $event.target)">
                                    <span style="padding-left: 24px;">{{page['name']}}</span>
                                </div>
                            </div>
                            
                        </div>
                        <div fxFlex="33.33%" style="padding-top: 20px;">
                            <h3>Virtual Campus</h3>
                            <div style="position: relative; display: flex; margin-left: auto; align-items: center; padding-top: 10px;" *ngFor="let page of virtualCampusPages">
                                <input class="form-check-input" [checked]="row['authorization']['virtualcampus'].includes(page['id_page'])" style="font-size:large; cursor: pointer; " type="checkbox" role="switch" (change)="checkPage('virtualcampus', row, page, $event.target)">
                                <span style="padding-left: 24px;">{{page['name']}}</span>
                            </div>
                        </div>
                        <div fxFlex="33.33%" style="padding-top: 20px;">
                            <h3>Virtual Class</h3>
                            <div style="position: relative; display: flex; margin-left: auto; align-items: center; padding-top: 10px;" *ngFor="let page of virtualClassPages">
                                <input class="form-check-input" [checked]="row['authorization']['virtualclass'].includes(page['id_page'])" style="font-size:large; cursor: pointer; " type="checkbox" role="switch" (change)="checkPage('virtualclass', row, page, $event.target)">
                                <span style="padding-left: 24px;">{{page['name']}}</span>
                            </div>
                        </div>
                    </div>
                    <div fxLayoutAlign="center center">
                        <button class="button-validate-small" (click) = "applyUpdate(row)">Validate</button>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="showedRows.length === 0">
            <tr ><td colspan="14" style="text-align: center;">No matching records found</td></tr>
        </tbody>
    </table>
</div>