import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import config from 'config/default.json';
import { TableComponent } from 'src/app/back-office/components/table/table.component';
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import { ClassifySettingComponent } from 'src/app/back-office/components/edit-project/project-editing/classify-setting/classify-setting.component';

@Component({
  selector: 'app-vca-content',
  templateUrl: './vca-content.component.html',
  styleUrls: ['./vca-content.component.scss', '../../components/table/table.component.scss']
})
export class VcaContentComponent extends TableComponent{
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,
    public sanitizer: DomSanitizer) {
      super(sanitizer);
  }
  @Output() inProgress = new EventEmitter<any>();
  @Input() userInfos: { [x:string]: any } = new Map();
  platformInfos:{[x: string]: any} = {origin: 'virtualcampus', key: 'vca', root: 'VIRTUALCAMPUS'};
  pageId = 'vca-content'
  modes:{ [x:string]: string|number }[] = [{id:1, value: 'Raw'}, {id: 2, value: 'Edited'},{id: 3, value: 'Classified'}, {id: 4, value: 'Edited & Classified'}, {id: 5, value: 'Archived'}];
  devices: any[] = [];

  ngOnInit(){
    
  }
  
  toggleRow(row:any, clickedElm:any, rowTab:any){
    let toExpand = true;
    for (var tdTab of rowTab.querySelectorAll('.to_skip_element')){
      if(tdTab.contains(clickedElm)){
        toExpand = false;
        break;
      }
    }
    if(toExpand){
      row['expanded'] =! row['expanded'];
    }
  }
  contentOption(rowInfos:any){
    let duplicateRows = this.dataSource.filter((row: any)=> rowInfos['id_content_duplicate'].includes(row.id_content))
    const dialogRef = this.dialog.open(ClassifySettingComponent, {
      width: '70%',
      position: {top: '30px' },
      data: {is_multiple: false, selected_rows: [rowInfos], duplicate_rows: duplicateRows, user_infos: this.userInfos, platform_infos: this.platformInfos, project_infos: []}
    });
    dialogRef.afterClosed().subscribe((response:any) => {
      
    });
  }
  loadContentData(data: any ){
    this.sharedService.sendData({in_progress: true});

    data.mode = data?.mode?.length? data.mode: [1, 2, 3, 4, 5];
    data.mode = this.computeMode(data.mode);
    
    data.value = data.value && data.value.length == 1? [data.value[0], data.value[0]]: data.value;
    this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/web/content/load', data).subscribe((response)=>{
      
      this.dataSource = response.map((row) => {
        row["expanded"] = false;
        row["checked"] = false;
        row["id_content_duplicate"] = row["id_content_duplicate"] && !['raw', 'archived'].includes(row['state'])? row["id_content_duplicate"].split(',').map((el:any) => parseInt(el)).filter((el:any) => el !== row["id_content"]) :[];
        return row;
      });
      
      this.showedRows = this.dataSource;
      this.devices = Array.from(new Set(response.map(el=>el.device)));
      this.sharedService.sendData({in_progress: false});
    })
  }
  computeMode(modes:any){
    let allStates:any = {
      1: 'raw',
      2: 'edited',
      3: 'classified',
      4: 'classified,edited',
      5: 'archived'
    }
    let states:any = []
    modes.forEach((i:any) => states.push(allStates[i]));
    return states;

  }
  setWaiting(value:any){
    this.inProgress.emit(value);
  }
  setUpdateStates(data:any){
    this.dataSource = this.dataSource.map((element:any) => {
      if (element.id_content == data.id_content) {
        element.state = data.state;
        element.active = data.active;
      }
      return element;
      });

    this.showedRows = this.showedRows.map((element:any) => {
      if (element.id_content == data.id_content) {
        element.state = data.state;
        element.active = data.active;
      }
      return element;
      });
  }
  setUpdatedContent(content:any){
    this.dataSource = this.dataSource.map((element:any) => {
      if (element.id_content == content.id_content) return this.formatRowDisplay(element, content)
      return element;
    });
    this.showedRows = this.showedRows.map((element:any) => {
      if (element.id_content == content.id_content) return this.formatRowDisplay(element, content)
      return element;
    });
  }
  formatRowDisplay(element:{ [x:string]: any }, content:{ [x:string]: any }){
    element['department']  =   content['department'];
    element['semester']    =   content['semester'];
    element['id_module']   =   content['id_module'];
    element['module']      =   content['module'];
    element['id_year']     =   content['id_year'];
    element['year']        =   content['year'];
    element['id_section']  =   content['id_section'];
    element['section']     =   content['section'];
    element['id_type']     =   content['id_type'];
    element['type']        =   content['type'];
    element['id_content_subject'] =   content['id_content_subject'];
    element['content_name']=   content['content_name'];
    element['content_url'] =   content['content_url'];
    element['state']       =   content['state'];
    element['active']      =   content['active'];
    element['duplicate']   =   content['duplicate'];
    element['state']       =   content['state'];
    element["is_duplicate"] = content["duplicate"].split(',').length > 1? true: false;
    let duplicates = content["duplicate"].split(',').map((el:any) => parseInt(el)).filter((el:any) => el !== content["id"]).sort((a:any, b:any) => a - b).map((el:any) => {return {key: 'replace-'+el, value: 'Replace '+el}});
    element["actions"] = [{key: 'raw', value: 'Raw'},{key: 'archived', value: 'Archive'}].concat(duplicates);
    return element;
  }
  applyFilterLocal(element:any, column:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applyFilter(element.value, column);
    this.cdRef.detectChanges();
  }
  applySortLocal(element:any, column:string, type:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applySort(element, column, type);
    this.cdRef.detectChanges();
  }
}
