import { ChangeDetectorRef, Component, ElementRef, Inject, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { SplittingModule } from './modules/spliting.module';
import { SplittedModule } from './modules/splited.module';
import { CleanModule } from './modules/clean.module';
import { ClassifyModule } from '../project-editing/modules/classify.module';

@Component({
  selector: 'app-project-spliting',
  templateUrl: './project-spliting.component.html',
  styleUrls: ['./project-spliting.component.scss']
})
export class ProjectSplitingComponent {
  constructor(
    
    public dialogRef: MatDialogRef<ProjectSplitingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [x:string]: any },
    private renderer: Renderer2,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    private apiService: ApiServiceModule,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    ) {}
    PDFJS = (window as { [key: string]: any })["pdfjs-dist/build/pdf"];
    public splittingModule: SplittingModule = new SplittingModule(this.renderer, this.elRef, this.cdRef, this.helper, this.apiService, this.dialog, this.sanitizer);
    public splittedModule: SplittedModule = new SplittedModule(this.renderer, this.elRef, this.cdRef, this.helper, this.apiService, this.dialog, this.sanitizer);
    public cleanedModule: CleanModule = new CleanModule(this.renderer, this.elRef, this.cdRef, this.helper, this.apiService, this.dialog, this.sanitizer);
    public classifyModule: ClassifyModule = new ClassifyModule(this.renderer, this.elRef, this.cdRef, this.helper, this.apiService, this.dialog, this.sanitizer);
    
    userInfos: {[x: string]: any}[] = this.data['user_infos'];
    projectInfos: {[x: string]: any} = this.data['project'];
    rawData: {[x: string]: any}[] = this.data['raw_data'];
    contentData: { [x:string]: any }[] = this.data['content_data'];
    classifyData: { [x:string]: any }[] = this.data['classify_data'];
    platformInfos: { [x:string]: any }[] = this.data['platform_infos'];


    inProgress = false;

    splittingActive = true;
    splittedActive = false;
    cleanedActive = false;
    classifiedActive = false;
    currentTask = ''
    taskLabels:{[x: string]: any}= {
      'task-splitting': 'Content Splitter',
      'task-splitted': 'Content Splitted',
      'task-cleaned': 'Content Cleaning',
      'task-classified': 'Content Classification'
    }

    ngOnInit(){
      this.splittingModule.init(this, this.splittedModule);
      this.splittedModule.init(this, this.cleanedModule);
      this.cleanedModule.init(this, null);
      this.classifyModule.init(this);
      this.currentTask = this.projectInfos['last_task'];
      if(this.currentTask == 'task-splitting') this.splittingModule.showSplitting();
      if(this.currentTask == 'task-splitted') this.splittedModule.showSplitted();
      if(this.currentTask == 'task-cleaned') this.cleanedModule.showCleaned();
      if(this.currentTask == 'task-classified') this.classifyModule.showClassified();
  
    }
    buildProcess(){
      if(this.splittingActive) this.splittingModule.computeSplit();
      if(this.splittedActive) this.splittedModule.computeClean();
    }
    onClose(): void {
      this.dialogRef.close();
    }
    activatePage(page:string){
      this.currentTask = page;
      this.splittingActive = this.currentTask == 'task-splitting'? true: false;
      this.splittedActive = this.currentTask == 'task-splitted'? true: false;
      this.cleanedActive = this.currentTask == 'task-cleaned'? true: false;
      this.classifiedActive = this.currentTask == 'task-classified'? true: false;
      this.cdRef.detectChanges();

    }
    removeChildsFromElement(element:any){
      while (element.hasChildNodes()){
        element.removeChild(element.lastChild);
      }
    }
}
