import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { EditorModule } from './modules/editor.module';
import { ClassifyModule } from './modules/classify.module';
import { CleanModule } from '../project-spliting/modules/clean.module';

@Component({
  selector: 'app-project-editing',
  templateUrl: './project-editing.component.html',
  styleUrls: ['./project-editing.component.scss'],
})
export class ProjectEditingComponent{
  constructor(
    
    public dialogRef: MatDialogRef<ProjectEditingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [x:string]: any },
    private renderer: Renderer2,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    private apiService: ApiServiceModule,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    ) {}
    public cleanedModule: CleanModule = new CleanModule(this.renderer, this.elRef, this.cdRef, this.helper, this.apiService, this.dialog, this.sanitizer);
    public editorModule: EditorModule = new EditorModule(this.renderer, this.elRef, this.cdRef, this.helper, this.apiService, this.dialog, this.sanitizer);
    public classifyModule: ClassifyModule = new ClassifyModule(this.renderer, this.elRef, this.cdRef, this.helper, this.apiService, this.dialog, this.sanitizer);

    PDFJS = (window as { [key: string]: any })["pdfjs-dist/build/pdf"];
    userInfos: { [x:string]: any }[] = this.data['user_infos'];
    projectInfos: {[x: string]: any} = this.data['project'];
    contentData: { [x:string]: any }[] = this.data['content_data'];
    classifyData: { [x:string]: any }[] = this.data['classify_data'];
    platformInfos: { [x:string]: any }[] = this.data['platform_infos'];
    source: string = this.data['source'];
    widthListZone: number = 40;


    inProgress = false;
    cleanedActive = false;
    editedActive = false;
    classifiedActive = false;
    currentTask = ''
    taskLabels:{[x: string]: any}= {
      'task-cleaned': 'Content Cleaning',
      'task-editing': 'Content Editing',
      'task-classified': 'Content Classification'
    }

    
    ngOnInit(){
      this.widthListZone = this.source == 'content'? 0: 40;
      
      this.editorModule.init(this, this.classifyModule);
      this.cleanedModule.init(this, this.editorModule);
      this.classifyModule.init(this);
      this.currentTask= this.projectInfos['last_task'];
      if(this.currentTask == 'task-cleaned') this.cleanedModule.showCleaned();
      if(this.currentTask == 'task-editing') this.editorModule.showEdited();
      if(this.currentTask == 'task-classified') this.classifyModule.showClassified();;
    }

    buildProcess(){
      if(this.cleanedModule) this.cleanedModule.computeAutoEditing();
    }
    
    onClose(): void {
      this.dialogRef.close();
    }
    activatePage(page:string){
      console.log(page)
      this.currentTask = page;
      this.cleanedActive = this.currentTask == 'task-cleaned'? true: false;
      this.editedActive = this.currentTask == 'task-editing'? true: false;
      this.classifiedActive = this.currentTask == 'task-classified'? true: false;
      
      this.cdRef.detectChanges();
    }
    removeChildsFromElement(element:any){
      while (element.hasChildNodes()) {
        element.removeChild(element.lastChild);
      }
    }
}
