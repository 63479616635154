<section>
    <h2>Content</h2>
    <app-vca-filter [pageId]="pageId" [userInfos]="userInfos" [modes]="modes" (filterDataOutputEvent)="loadContentData($event)">
    </app-vca-filter>
    <div style="text-align:left; padding: 20px;">
        <input class="float-right col-input" style="width: 180px; float:right;" (keyup)="applyFilterLocal($event.target, 'all_column')" placeholder="Search">
        <p>Total: {{this.showedRows.length}}</p>
    </div>
    <div class="table_div">
        <table>
            <thead>
            <tr>
                <th><div class="column-name">Id</div></th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id_content', 'number')"></mat-icon>
                    <div class="column-name">Id Content</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id_content')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'department', 'string')"></mat-icon>
                    <div class="column-name">Department</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'department')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'semester', 'string')"></mat-icon>
                    <div class="column-name">Semester</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'semester')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'module', 'string')"></mat-icon>
                    <div class="column-name">Module</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'module')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'year', 'string')"></mat-icon>
                    <div class="column-name">Year</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'year')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'section', 'string')"></mat-icon>
                    <div class="column-name">Section</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'section')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'type', 'string')"></mat-icon>
                    <div class="column-name">Type</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'type')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'credit', 'number')"></mat-icon>
                    <div class="column-name">Credit</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'credit')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'subscribe', 'number')"></mat-icon>
                    <div class="column-name">Subscribes</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'subscribe')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'state', 'string')"></mat-icon>
                    <div class="column-name">State</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'state')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'active', 'string')"></mat-icon>
                    <div class="column-name">Active</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'active')"></div>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody #row_tbody *ngFor="let row of showedRows; let i = index">
                <tr (click)="toggleRow(row, $event.target, row_tbody)">
                    <td>{{i + 1}}</td>
                    <td>{{ row['id_content'] }}</td>
                    <td>{{ row['department'] }}</td>
                    <td>{{ row['semester'] }}</td>
                    <td>{{ row['module'] }}</td>
                    <td style="white-space: nowrap;">{{ row['year'] }}</td>
                    <td>{{ row['section'] }}</td>
                    <td >{{ row['type']}} {{ row['id_content_subject']}}</td>
                    <td>{{ row['credit'] }}</td>
                    <td>{{ row['subscribe'] }}</td>
                    <td [innerHTML]="stateTagsDisplay(row['state'])"></td>
                    <td [innerHTML]="stateTagsDisplay(row['active'])"></td>
                    <td class="to_skip_element select_container">
                        <div *ngIf="row['id_content_duplicate'] > 0" class="duplicate-mark"></div>
                        <mat-icon sorted="" fontIcon="more_vert" style="color: gray; font-size: 20px;" (click)="contentOption(row)"></mat-icon>
                    </td>
                </tr>
                <tr class="expand_detail" *ngIf="row['expanded']">
                    <td colspan="14">
                        <app-vca-detail-content [rowInfos]="row" [userInfos]="userInfos" (inProgress)="setWaiting($event)" (updateStates)="setUpdateStates($event)" (updateContent)="setUpdatedContent($event)"></app-vca-detail-content>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="showedRows.length === 0">
                <tr ><td colspan="14" style="text-align: center;">No matching records found</td></tr>
            </tbody>
        </table>
    </div>
</section>