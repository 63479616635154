import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, Output, SimpleChanges } from '@angular/core';
import {FormControl, FormGroup, FormBuilder} from '@angular/forms';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import config from 'config/default.json';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
@Component({
  selector: 'app-vca-filter',
  templateUrl: './vca-filter.component.html',
  styleUrls: ['./vca-filter.component.scss']
})
export class VcaFilterComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    private sharedService: SharedService,
    private zone: NgZone
    ) {}
  countryControl = new FormControl();
  institutionControl = new FormControl();
  structureControl = new FormControl();
  departmentControl = new FormControl();
  modeControl = new FormControl();

  countries: { [x:string]: string|number|boolean }[] = [];
  institutions: { [x:string]: string|number|boolean }[] = [];
  structures: { [x:string]: string|number|boolean }[] = [];
  departments: { [x:string]: string|number|boolean }[] = [];

  selectedCountries: any[] = [];
  selectedInstitutions: any[] = [];
  selectedStructures: any[] = [];
  selectedDepartments: any[] = [];

  userFilter: { [x:string]: any } = new Map();
  lastTable!:string;
  
  @Input() userInfos: { [x:string]: any } = new Map();
  @Input() modes:any[] = [];

  @Input() pageId!: string;
  @Input() pageName?: string | undefined;
  @Output() filterDataOutputEvent = new EventEmitter<any>();
  showDepartment!: boolean;

  ngOnInit(){
    this.fillFilter();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userInfos'] && !changes['userInfos'].firstChange) {
      this.fillFilter();
      this.cdRef.detectChanges();
    }
  }
  fillFilter(){
    this.showDepartment = ['vca-upload-content'].includes(this.pageId)? false: true;
    let userNavigationInfos = this.userInfos['navigation_infos'];
    this.userFilter = userNavigationInfos && userNavigationInfos["vca_filter_"+this.pageId]? userNavigationInfos["vca_filter_"+this.pageId]: {};
    this.lastTable = this.userFilter['last_table'];
    this.selectedCountries = this.userFilter['country']? this.userFilter['country']: [];
    this.selectedInstitutions = this.userFilter['institution']? this.userFilter['institution']: [];
    this.selectedStructures = this.userFilter['structure']? this.userFilter['structure']: [];
    this.selectedDepartments = this.userFilter['department']? this.userFilter['department']: [];
    this.loadCountry();
    this.chargeData();
  }
  loadCountry(){
    this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/country', {}).subscribe((response:any)=>{
      this.countries = response;
      this.institutions = [];
      this.structures = [];
      this.departments = [];
      this.institutionControl.setValue([]);
      this.structureControl.setValue([]);
      this.departmentControl.setValue([]);
      this.updateInstitution();
      this.selectedCountries = (this.countryControl.value && this.countryControl.value.length)? this.countryControl.value: this.selectedCountries;
      this.countryControl.setValue(this.selectedCountries);
    });
  }
  updateInstitution(keyUpdate:any = null){
    if(keyUpdate) this.selectedCountries = this.countryControl.value;
    if(this.selectedCountries && this.selectedCountries.length){
      let selectedCountries = this.selectedCountries.length > 1? this.selectedCountries: [this.selectedCountries[0], this.selectedCountries[0]];
      this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/institution', {parent_ids: selectedCountries, key_update: keyUpdate, id_user: this.userInfos['root_id_user']}).subscribe((response:any)=>{
        this.institutions = response;
        this.structures = [];
        this.departments = [];
        if(keyUpdate) this.lastTable = 'country';
        this.structureControl.setValue([]);
        this.departmentControl.setValue([]);
        this.selectedInstitutions = (this.institutionControl.value && this.institutionControl.value.length)? this.institutionControl.value: this.selectedInstitutions;
        this.institutionControl.setValue(this.selectedInstitutions);
        this.updateStructure();
      });
    }else{
      if(keyUpdate) this.lastTable = '';
      this.institutions = [];
      this.structures = [];
      this.departments = [];
      this.institutionControl.setValue([]);
      this.structureControl.setValue([]);
      this.departmentControl.setValue([]);
    }
  }
  
  updateStructure(keyUpdate:any = null){
    if(keyUpdate) this.selectedInstitutions = this.institutionControl.value;
    if(this.selectedInstitutions && this.selectedInstitutions.length){
      let selectedInstitutions = this.selectedInstitutions.length > 1? this.selectedInstitutions: [this.selectedInstitutions[0], this.selectedInstitutions[0]];
      this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/structure', {parent_ids: selectedInstitutions, key_update: keyUpdate, id_user: this.userInfos['root_id_user']}).subscribe((response:any)=>{
        this.structures = response;
        this.departments = [];
        if(keyUpdate) this.lastTable = 'institution';
        this.structureControl.setValue([]);
        this.departmentControl.setValue([]);
        this.selectedStructures = (this.structureControl.value && this.structureControl.value.length)? this.structureControl.value: this.selectedStructures
        this.structureControl.setValue(this.selectedStructures);
        this.updateDepartment();
      });
    }else{
      if(keyUpdate) this.lastTable = 'country';
      this.structures = [];
      this.departments = [];
      this.structureControl.setValue([]);
      this.departmentControl.setValue([]);
    }
  }
  updateDepartment(keyUpdate:any = null){
    if(keyUpdate) this.selectedStructures = this.structureControl.value;
    if(this.selectedStructures && this.selectedStructures.length){
      let selectedStructures = this.selectedStructures.length > 1? this.selectedStructures: [this.selectedStructures[0], this.selectedStructures[0]];
      this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/department', {parent_ids: selectedStructures, key_update: keyUpdate, id_user: this.userInfos['root_id_user']}).subscribe((response:any)=>{
        this.departments = response;
        if(keyUpdate) this.lastTable = 'structure';
        let selectedDepartments = (this.departmentControl.value && this.departmentControl.value.length)? this.departmentControl.value: this.userFilter['department'];
        if(selectedDepartments && selectedDepartments.length){
          this.departmentControl.setValue(selectedDepartments);
        }
      });
    }else {
      this.departments = []; 
      this.departmentControl.setValue([]);
      if(keyUpdate) this.lastTable = 'institution';
    }
  }
  setDepartment(){
    this.selectedDepartments = this.departmentControl.value;
    if(this.selectedDepartments && this.selectedDepartments.length){
      this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/save_user_navigation', {parent_ids: this.selectedDepartments, key_update: "vca_filter_"+this.pageId, last_table: 'department', id_user: this.userInfos['root_id_user']}).subscribe((response:any)=>{
        this.lastTable = 'department';
      });
    }else this.lastTable = 'structure';
  }
  loadPreviousFilters(){
  }

  selectedElement(selectedOptions: FormControl, element: { [x:string]: string|number|boolean }): boolean {
    return Array.isArray(selectedOptions.value) && selectedOptions.value.includes(element['id']);
  }

  chargeData() {
    if(this.lastTable){
      let value =  this.lastTable == 'country'? this.selectedCountries: 
      (this.lastTable == 'institution'? this.selectedInstitutions:
      (this.lastTable == 'structure'? this.selectedStructures:
      (this.lastTable == 'department'? this.selectedDepartments: [])));

      let parentsData = {country: this.countries.filter((el:any)=> this.selectedCountries.includes(el['id'])),
                          institution: this.institutions.filter((el:any)=> this.selectedInstitutions.includes(el['id'])),
                          structure: this.structures.filter((el:any)=> this.selectedStructures.includes(el['id'])),
                          department: this.departments.filter((el:any)=> this.selectedDepartments.includes(el['id']))};
        let data = {key: this.lastTable, value: value, mode: this.modeControl.value? this.modeControl.value: [], parents: parentsData};
        this.filterDataOutputEvent.emit(data);
      }else this.filterDataOutputEvent.emit({});
    
    
  }
}
