import { Component, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { SharedService } from './back-office/modules/shared-service.service';
import { ProjectSplitingComponent } from './back-office/components/edit-project/project-spliting/project-spliting.component';
import { ProjectEditingComponent } from './back-office/components/edit-project/project-editing/project-editing.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  urlDomain = window.location.hostname;
  subDomain:string = '';
  title = 'back-office';
  isLoggin = true
  isAdmin = false;
  
  isRegistration = false;
  userInfos:any;
  
  constructor(private elRef:ElementRef, private cdRef: ChangeDetectorRef,
    private sharedService: SharedService,
    public dialog: MatDialog) {}
  
  ngOnInit(){
  }
    
  connexionAuthenticated(userInfos: any){
    if(userInfos && userInfos.authorized && userInfos.privilege > 1){
        this.isRegistration = false;
        this.isLoggin = false;
        this.isAdmin = true;
        this.userInfos = userInfos;
        this.cdRef.detectChanges();
    }
  }
  checkUserConnexion(userInfos:any){
    
  }
  createNewUser(){
    this.isLoggin = false;
    this.isRegistration = true;
  }
  connectUser(){
    this.isRegistration = false;
    this.isLoggin = true;
  }
}
