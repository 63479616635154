import { ChangeDetectorRef, Component , ElementRef, HostListener, Inject, Renderer2, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import config from 'config/default.json';

@Component({
  selector: 'app-classify-setting',
  templateUrl: './classify-setting.component.html',
  styleUrls: ['./classify-setting.component.scss']
})
export class ClassifySettingComponent {
  constructor(
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<ClassifySettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [x:string]: any }
    ) {}
  contentControl = new FormControl();
  actionControl = new FormControl();
  contentInputControl = new FormControl();
  actionValueControl = new FormControl();
  rowInfos: { [x:string]: any } = {};
  isMultiple: { [x:string]: any } = this.data['is_multiple'];
  selectedRows: { [x:string]: any }[] = this.data['selected_rows'];
  duplicatesRows: { [x:string]: any }[] = this.data['duplicate_rows'];
  
  userInfos: { [x:string]: any } = this.data['user_infos'];
  platformInfos: { [x:string]: any } = this.data['platform_infos'];
  projectInfos: { [x:string]: any } = this.data['project_infos'];
  actionMode = 'input';

  contentTitle = '';
  responseData: { [x:string]: any } = {data: null};
  contentActions!:{ [x:string]: any }[];
  searchInProgress:boolean = false;

  ngOnInit(){
    if(!this.isMultiple){
      
      this.rowInfos = this.selectedRows[0];
      console.log(this.rowInfos)
      this.contentTitle = this.platformInfos['origin'] == 'virtualcampus'? this.rowInfos['id_content']+' - '+this.rowInfos['department']+' - '+this.rowInfos['semester']+' - '+this.rowInfos['module']+' - '+this.rowInfos['year']+' - '+this.rowInfos['section']+' - '+this.rowInfos['type']:
                                                                        this.rowInfos['id_content']+' - '+this.rowInfos['belongs']+' - '+this.rowInfos['class']+' - '+this.rowInfos['study']+' - '+this.rowInfos['module']+' - '+this.rowInfos['year']+' - '+this.rowInfos['period']+' - '+this.rowInfos['section']+' - '+this.rowInfos['type']
      this.actionControl.setValue('import');
      this.actionValueControl.setValue('import');
    }else{
      this.contentTitle = this.selectedRows.length+' Content(s) selected'
    }
    
  
  }
  applyAction(){
    let action = this.actionControl.value;
    let idContent = this.contentControl.value;
    let projectName = this.actionValueControl.value;
    if(this.userInfos['privilege'] >= 3){
      if(action){
        let data = {
          action: action,
          contents: this.selectedRows,
          user_infos: this.userInfos,
          id_content_target: idContent,
          project_name: projectName,
          platform_infos: this.platformInfos,
          project_infos: this.projectInfos
        }
        this.apiService.queryData(config.master.ndapi.url +'/enovsky/web/content/content_setting', data).subscribe((response:any)=>{
          this.helper.showToast(response.message);
          if(response.status){
            this.responseData = response;
            this.dialogRef.close(response);
          }
        })
      }else this.helper.showToast('No action is specified...')
    }else this.helper.showToast('No enough privileges for this action...')
    
    
  }
  switchAction(){
    let action = this.actionControl.value;
    if(['replace', 'archive_replace'].includes(action)) this.actionMode = 'select';
    if(['archive','raw','import', 'duplicate'].includes(action)) this.actionMode = '';
  }
  close(){
    this.dialogRef.close(this.responseData);
  }
}
