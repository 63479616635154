
<div fxLayout="row" fxLayout.xs="column">
    <div class="class-mat-field" fxFlex="16.66">
        <mat-select placeholder="Country" [formControl]="countryControl" (selectionChange)="updateRegion()">
            <mat-option [value]="element['id']" *ngFor="let element of countries; let i = index">{{element['full_name']}}</mat-option>
        </mat-select>
    </div>
    <div class="separator"></div>
    <div class="class-mat-field" fxFlex="16.66">
        <mat-select placeholder="Cycle" [formControl]="cycleControl" (selectionChange)="updateInstitution('cycle')">
            <mat-option [value]="element['id']" *ngFor="let element of cycles; let i = index">{{element['name']}}</mat-option>
        </mat-select>
    </div>
    <div class="separator"></div>
    <div class="class-mat-field" fxFlex="16.66">
        <mat-select placeholder="Region" [formControl]="regionControl" (selectionChange)="updateInstitution('region')">
            <mat-option [value]="element['id']" *ngFor="let element of regions; let i = index">{{element['full_name']}}</mat-option>
        </mat-select>
    </div>
    <div class="separator"></div>
    <div class="class-mat-field" fxFlex="16.66">
        <mat-select placeholder="Institution" [formControl]="institutionControl" (selectionChange)="setInstitution()">
            <input class="col-input-filter" [formControl]="searchInstitutionControl">
            <mat-option [value]="element['id']" *ngFor="let element of filteredInstitutions; let i = index">{{element['name']}}</mat-option>
        </mat-select>
    </div>
    <div class="separator"></div>
    <div class="class-mat-field" fxFlex="16.66">
        <mat-select class="class-select-field" placeholder="Mode" [formControl]="modeControl" class="multiselect" multiple>
            <mat-option [value]="element['id']" *ngFor="let element of modes; let i = index">
                <span class="mat-option-content">{{element['value']}}</span>
                <span class="mat-option-checkbox">
                    <mat-icon *ngIf="selectedElement(modeControl, element)" class="mat-icon material-icons check-icon" fontIcon="done"></mat-icon>
                </span>
            </mat-option>
        </mat-select>
    </div>
    <div class="separator"></div>
    <div class="class-mat-field" fxFlex="16.66"  fxLayoutAlign="center center">
        <button class="button-validate-small" (click) = "chargeData('user')">Validate</button>
    </div>
</div>