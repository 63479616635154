<div class="container">
    <div class="card" style="position: relative; width: 70%;" >
        <h1>Comment Supprimer Mon Compte et Mes Données</h1>
        <p>
            Vous pouvez supprimer votre compte et les données associées en suivant les étapes ci-dessous :
        </p>
        <ol>
            <li>Connectez-vous à l'application avec vos identifiants.</li>
            <li>Accédez à <strong>Mon Compte</strong> dans le menu principal.</li>
            <li>Sélectionnez l'option <strong>Supprimer mon Compte</strong>.</li>
            <li>Confirmez votre suppresssion en renseignant votre mot de passe</li>
            <li>Un code vous sera envoyé pour valider la suppression</li>
        </ol>
        <div class="screenshot">
            <h2>Exemple de la Page de Suppression :</h2>
            <p>Voici une capture d'écran de la page où vous pouvez supprimer votre compte :</p>
            <img src="assets/images/delete_account_screen.png" alt="Capture d'écran de la page 'Supprimer mon Compte'" draggable="false" height="400">
        </div>
        <div class="contact">
            <p>
                Si vous avez des questions ou besoin d'assistance, veuillez nous contacter à 
                <a href="mailto:support@enovsky.com">support@enovsky.com</a>.
            </p>
        </div>

    </div>
</div>