<div style="padding: 10px;">
    <h1>Politique de confidentialité</h1>
    <p>Nous respectons votre vie privée et nous nous engageons à la protéger en respectant cette politique de confidentialité. Cette politique décrit les types d'informations que nous pouvons collecter auprès de vous ou que vous pouvez fournir en tant qu'informations personnelles dans le service des applications mobile « Virtual Campus, Virtual Class» et tous ses produits et services associés, ainsi que nos pratiques concernant la collecte, l'utilisation, la conservation, la protection et la divulgation de ces informations personnelles. Elle décrit également les choix qui s'offrent à vous concernant notre utilisation de vos informations personnelles et la manière dont vous pouvez y accéder et les mettre à jour.</p>
    <p>Cette politique est un accord juridiquement contraignant entre vous et Enovsky. Si vous entrez dans cet accord au nom d'une entreprise ou d'une autre entité juridique, vous déclarez avoir l'autorité pour lier cette entité à cet accord, auquel cas le terme « utilisateur » se réfère à cette entité. Si vous n'avez pas une telle autorité, ou si vous n'êtes pas d'accord avec les termes de cet accord, vous ne devez pas accepter cet accord et vous ne pouvez pas accéder ni utiliser l'application mobile et les services. En accédant et en utilisant l'application mobile et les services, vous reconnaissez avoir lu, compris et accepté d'être lié par les termes de cette politique. Cette politique ne s'applique pas aux pratiques des entreprises que nous ne possédons pas ou ne contrôlons pas, ou des individus que nous n'employons pas ou ne gérons pas.</p>
    <div class="wpembed-toc">
        <h3>Table des matières</h3>
        <ol class="wpembed-toc">
            <li class="menu-section" (click)="autoScrollTo('collection-of-personal-information')">Collecte des informations personnelles</li>
            <li class="menu-section" (click)="autoScrollTo('privacy-of-children')">Vie privée des enfants</li>
            <li class="menu-section" (click)="autoScrollTo('use-and-processing-of-collected-information')">Utilisation et traitement des informations collectées</li>
            <li class="menu-section" (click)="autoScrollTo('managing-information')">Gestion des informations</li>
            <li class="menu-section" (click)="autoScrollTo('disclosure-of-information')"> Divulgation des informations</li>
            <li class="menu-section" (click)="autoScrollTo('retention-of-information')">Conservation des informations</li>
            <li class="menu-section" (click)="autoScrollTo('email-marketing')">Marketing par e-mail</li>
            <li class="menu-section" (click)="autoScrollTo('push-notifications')">Notifications push</li>
            <li class="menu-section" (click)="autoScrollTo('links-to-other-resources')">Liens vers d'autres ressources</li>
            <li class="menu-section" (click)="autoScrollTo('information-security')">Sécurité des informations</li>
            <li class="menu-section" (click)="autoScrollTo('data-breach')">Violation de données</li>
            <li class="menu-section" (click)="autoScrollTo('changes-and-amendments')">Modifications et amendements</li>
            <li class="menu-section" (click)="autoScrollTo('acceptance-of-this-policy')">Acceptation de cette politique</li>
            <li class="menu-section" (click)="autoScrollTo('contacting-us')">Nous contacter</li>
        </ol>
    </div>

    <h2 id="collection-of-personal-information">Collecte des informations personnelles</h2>
    <p>Vous pouvez accéder et utiliser l'application mobile et les services sans nous dire qui vous êtes ou révéler toute information qui permettrait de vous identifier comme une personne spécifique. Cependant, si vous souhaitez utiliser certaines fonctionnalités proposées dans l'application mobile, il se peut qu'on vous demande de fournir certaines informations personnelles (par exemple, votre nom et votre adresse e-mail).</p>
    <p>Nous recevons et stockons toute information que vous nous fournissez volontairement lorsque vous créez un compte, publiez du contenu ou remplissez des formulaires dans l'application mobile. Lorsque cela est nécessaire, ces informations peuvent inclure les éléments suivants :</p>
    <ul>
        <li>Détails du compte (comme le nom d'utilisateur, l'ID utilisateur unique, le mot de passe, etc.)</li>
        <li>Informations de contact (comme l'adresse e-mail, le numéro de téléphone, etc.)</li>
        <li>Informations personnelles de base (comme le nom, le pays de résidence, etc.)</li>
        <li>Certaines fonctionnalités sur le dispositif mobile (comme les contacts, le calendrier, la galerie, etc.)</li>
    </ul>
    <p>Vous pouvez choisir de ne pas nous fournir vos informations personnelles, mais dans ce cas, vous ne pourrez peut-être pas profiter de certaines fonctionnalités de l'application mobile. Les utilisateurs qui ne sont pas sûrs des informations obligatoires peuvent nous contacter.</p>

    <h2 id="privacy-of-children">Vie privée des enfants</h2>
    <p>Nous ne collectons pas délibérément d'informations personnelles auprès d'enfants de moins de 13 ans. Si vous avez moins de 13 ans, veuillez ne pas soumettre d'informations personnelles via l'application mobile et les services. Si vous avez des raisons de croire qu'un enfant de moins de 13 ans nous a fourni des informations personnelles via l'application mobile et les services, veuillez nous contacter pour demander que nous supprimions ces informations personnelles de nos services.</p>
    <p>Nous encourageons les parents et les tuteurs légaux à surveiller l'utilisation d'Internet de leurs enfants et à aider à faire respecter cette politique en instruisant leurs enfants de ne jamais fournir d'informations personnelles via l'application mobile et les services sans leur permission. Nous demandons également à tous les parents et tuteurs légaux supervisant les soins des enfants de prendre les précautions nécessaires pour s'assurer que leurs enfants sont instruits de ne jamais divulguer d'informations personnelles en ligne sans leur permission.</p>

    <h2 id="use-and-processing-of-collected-information">Utilisation et traitement des informations collectées</h2>
    <p>Nous agissons en tant que responsable du traitement et sous-traitant des informations personnelles, sauf si nous avons conclu un accord de traitement des données avec vous, auquel cas vous seriez le responsable du traitement et nous serions le sous-traitant.</p>
    <p>Notre rôle peut également différer en fonction de la situation spécifique impliquant des informations personnelles. Nous agissons en tant que responsable du traitement lorsque nous vous demandons de soumettre vos informations personnelles nécessaires pour garantir votre accès et votre utilisation de l'application mobile et des services. Dans ces cas, nous sommes responsables des informations personnelles car nous déterminons les finalités et les moyens de traitement de ces informations.</p>
    <p>Nous agissons en tant que sous-traitant dans les situations où vous soumettez des informations personnelles via l'application mobile et les services. Nous ne possédons, ne contrôlons ni ne prenons de décisions concernant les informations soumises, et ces informations personnelles ne sont traitées que conformément à vos instructions. Dans ces cas, l'utilisateur fournissant les informations personnelles agit en tant que responsable du traitement.</p>
    <p>Pour rendre l'application mobile et les services disponibles, ou pour respecter une obligation légale, nous pouvons être amenés à collecter et utiliser certaines informations personnelles. Si vous ne fournissez pas les informations demandées, nous ne pourrons peut-être pas vous fournir les produits ou services demandés. Toute information que nous collectons auprès de vous peut être utilisée dans les buts suivants :</p>
    <ul>
        <li>Créer et gérer les comptes utilisateurs</li>
        <li>Exécuter et gérer l'application mobile et les services</li>
    </ul>

    <p>Le traitement de vos informations personnelles dépend de la manière dont vous interagissez avec l'application mobile et les services, de votre localisation et si l'une des conditions suivantes s'applique : (i) vous avez donné votre consentement pour un ou plusieurs objectifs spécifiques ; (ii) la fourniture d'informations est nécessaire à l'exécution d'un contrat avec vous et/ou pour toute obligation précontractuelle ; (iii) le traitement est nécessaire pour se conformer à une obligation légale à laquelle vous êtes soumis ; (iv) le traitement est lié à une tâche effectuée dans l'intérêt public ou dans l'exercice d'une autorité publique qui nous est conférée ; (v) le traitement est nécessaire aux fins des intérêts légitimes poursuivis par nous ou un tiers.</p>
    <p>Notez que, sous certaines législations, nous pouvons être autorisés à traiter des informations jusqu'à ce que vous vous y opposiez en vous désinscrivant, sans avoir à nous appuyer sur le consentement ou toute autre base légale. Dans tous les cas, nous serons heureux de clarifier la base légale spécifique applicable au traitement et en particulier si la fourniture d'informations personnelles est une exigence légale ou contractuelle, ou une exigence nécessaire à la conclusion d'un contrat.</p>

    <h2 id="managing-information">Gestion des informations</h2>
    <p>Vous pouvez supprimer certaines informations personnelles que nous avons sur vous. Les informations personnelles que vous pouvez supprimer peuvent changer à mesure que l'application mobile et les services changent. Cependant, lorsque vous supprimez des informations personnelles, nous pouvons conserver une copie des informations non modifiées dans nos archives pour la durée nécessaire à respecter nos obligations envers nos affiliés et partenaires, et pour les objectifs décrits ci-dessous.</p>

    <h2 id="disclosure-of-information">Divulgation des informations</h2>
    <p>Pour maintenir le plus haut niveau de confidentialité et protéger vos informations personnelles dans toute leur étendue, nous ne partageons pas vos informations personnelles avec qui que ce soit et pour aucune raison.</p>

    <h2 id="retention-of-information">Conservation des informations</h2>
    <p>Nous conserverons et utiliserons vos informations personnelles pendant la période nécessaire tant que votre compte sera actif ou pour vous fournir des services. Si vous souhaitez que nous supprimions vos informations ou si vous avez des questions sur la gestion de vos informations, contactez-nous via les informations ci-dessous.</p>

    <h2 id="email-marketing">Marketing par e-mail</h2>
    <p>Nous pouvons utiliser vos informations pour vous envoyer des e-mails avec des informations pertinentes pour l'application mobile et les services. Vous pouvez vous désinscrire de nos communications par e-mail à tout moment en suivant les instructions dans chaque e-mail.</p>

    <h2 id="push-notifications">Notifications push</h2>
    <p>Nous pouvons vous envoyer des notifications push si vous avez activé cette fonctionnalité. Vous pouvez désactiver cette fonctionnalité dans les paramètres de votre appareil.</p>

    <h2 id="links-to-other-resources">Liens vers d'autres ressources</h2>
    <p>Notre application mobile et nos services peuvent contenir des liens vers d'autres sites web. Nous ne sommes pas responsables des pratiques de confidentialité de ces sites web et nous vous encourageons à lire leurs politiques de confidentialité avant de fournir vos informations personnelles.</p>

    <h2 id="information-security">Sécurité des informations</h2>
    <p>Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos informations personnelles. Cependant, aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sûre. Par conséquent, bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos informations personnelles, nous ne pouvons pas garantir leur sécurité absolue.</p>

    <h2 id="data-breach">Violation de données</h2>
    <p>Si nous découvrons une violation de données impliquant vos informations personnelles, nous vous en informerons conformément aux lois applicables.</p>

    <h2 id="changes-and-amendments">Modifications et amendements</h2>
    <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Nous vous informerons de tout changement en publiant une nouvelle version sur cette page et en mettant à jour la date de la dernière révision.</p>

    <h2 id="acceptance-of-this-policy">Acceptation de cette politique</h2>
    <p>En utilisant l'application mobile et les services, vous acceptez cette politique de confidentialité. Si vous n'acceptez pas les termes de cette politique, veuillez ne pas utiliser l'application mobile et les services.</p>

    <h2 id="contacting-us">Nous contacter</h2>
    <p>Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter à l'adresse suivante :</p>
    <a href="mailto:contact@enovsky.com">contact@enovsky.com</a>.
    <p>Ce document a été mis à jour le 1er décembre 2024</p>
</div>