import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-data-policy',
  templateUrl: './data-policy.component.html',
  styleUrls: ['./data-policy.component.scss']
})
export class DataPolicyComponent {
  constructor(
    private elRef: ElementRef,
  ) {}
  scrollY = 0;
  distance = 50;
  speed = 24;
  autoScrollTo(identifier: string) {
    /* const targetElement = this.elRef.nativeElement.querySelector('#' + identifier);
    if (!targetElement) return;

    const targetY = targetElement.getBoundingClientRect().top + window.scrollY;

    const currentY = window.scrollY;
    const bodyHeight = document.body.offsetHeight;
    const yPos = currentY + window.innerHeight;

    if (yPos > bodyHeight) return;

    const animator = setTimeout(() => {
      this.autoScrollTo(identifier);
    }, 24);

    if (currentY < targetY - this.distance) {
      const scrollY = currentY + this.distance;
      window.scroll(0, scrollY);
    } else {
      clearTimeout(animator);
    } */
  }
}
